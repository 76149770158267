<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-8>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs11 pt-5 pb-4>
            <span   class="mainfont"
                style="color: black; font-size: 16px; font-weight: bold">Product Details</span>
          </v-flex>
          <v-flex xs11>
            <v-layout wrap>
              <v-flex xs4>
                <v-layout wrap>
                  <v-flex xs11 class="mainfont">
                    <span
                      class="mainfont"
                      style="color: black; font-size: 14px"
                    >
                      Name</span
                    >
                    <v-form @submit.prevent="validateInput">
                      <v-text-field
                        color="black"
                        outlined
                        background-color="white"
                        dense
                        type="text"
                        v-model="name"
                        hide-details
                      >
                      </v-text-field>
                    </v-form>
                  </v-flex>

                  <v-flex xs11 pt-2 class="mainfont">
                    <span
                      class="mainfont"
                      style="color: black; font-size: 14px"
                    >
                      Price (Rs)</span
                    >
                    <v-form @submit.prevent="validateInput">
                      <v-text-field
                        color="black"
                        outlined
                        background-color="white"
                        dense
                        type="text"
                        v-model="price"
                        hide-details
                      >
                      </v-text-field>
                    </v-form>
                  </v-flex>
                  <v-flex xs11 pt-2 class="mainfont" style="font-size: 20px">
                    <span
class="mainfont"
style="color: black; font-size: 14px"
>
Image</span
>
<ImageComp
class="mainfont"
style="color: black; font-size: 14px"
:singleImage="curid.photo"
@stepper="winStepper"
:height="'800'"
:width="'1300'"
:heading="'Upload Image'"
:componentType="'topImage'"
/>




                  </v-flex>
                
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap>
                  <v-flex xs11 class="mainfont">
                    <span
                      class="mainfont"
                      style="color: black; font-size: 14px"
                    >
                      Category</span
                    >
                    <v-autocomplete
                      outlined
                      dense
                      color="black"
                      :items="category"
                      v-model="categoryId"
                      item-text="name"
                      item-value="_id"
                      item-color="#FF1313"
                      hide-details
                      clearable
                      class="custom-autocomplete  mainfont"
                    >
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs11 pt-2 class="mainfont">
                    <span
                      class="mainfont"
                      style="color: black; font-size: 14px"
                    >
                      Stock</span
                    >
                    <v-form @submit.prevent="validateInput">
                      <v-text-field
                        color="black"
                        outlined
                        background-color="white"
                        dense
                        type="text"
                        v-model="stock"
                        hide-details
                      >
                      </v-text-field>
                    </v-form>
                  </v-flex>

                
                </v-layout>
              </v-flex>

              <v-flex xs4>
                <v-layout wrap>
                  <v-flex xs8 pr-3 class="mainfont">
                    <span
                      class="mainfont"
                      style="color: black; font-size: 14px"
                    >
                      Weight</span
                    >
                    <v-form @submit.prevent="validateInput">
                      <v-text-field
                        color="black"
                        outlined
                        background-color="white"
                        dense
                        type="text"
                        v-model="weight"
                        hide-details
                      >
                      </v-text-field>
                    </v-form>
                  </v-flex>
                  <v-flex xs4 class="mainfont">
                    <span
                      class="mainfont"
                      style="color: black; font-size: 14px"
                    >
                      Unit</span
                    >
                    <v-autocomplete
                      outlined
                      dense
                      color="black"
                      :items="itemArray2"
                      v-model="weightType"
                      item-text="name"
                      item-value="_id"
                      item-color="#FF1313"
                      hide-details
                      clearable
                      class="custom-autocomplete  mainfont"
                    >
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 pt-2 class="mainfont">
                   <span
class="mainfont"
style="color: black; font-size: 14px"
>
Description
</span>
<v-form @submit.prevent="validateInput">
<v-text-field
  color="black"
  outlined
  background-color="white"
  type="text"
  v-model="description"
  hide-details
>
</v-text-field>
</v-form>

                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12>
                <v-layout justify-end wrap>
                  <v-flex xs2 pl-8>
                    <v-btn
                      dark
                      @click="add()"
                      color="#13736f"
                      width="100%"
                      class="no-uppercase"
                      ><span
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: white;
                          letter-spacing: 1px;
                          cursor: pointer;
                        "
                        >Add
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  
  <script>
import axios from "axios";
import ImageComp from "@/components/Common/singleImage";

export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      preview: null,
      weight: null,
      price: null,
      stock: null,
      categoryId: null,
      link: null,
      prev: null,
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      category: [],
      weightType: 'g',
      msg: null,
      limit: 10,
      switch1: false,
      url: null,
      itemArray2: ["kg", "g"],
      categoryName: null,
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      editdailog: false,
      curid: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    add() {
      this.formData.append("name", this.name);
      this.formData.append("categoryId", this.categoryId);
      this.formData.append("weight", this.weight);
      this.formData.append("price", this.price);
      this.formData.append("stock", this.stock);
      this.formData.append("description", this.description);
      this.formData.append("photos", this.topImage);
      this.formData.append("weightType", this.weightType);

      
      axios({
        method: "POST",
        url: "/admin/product/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(window_data) {
      if (window_data.type == "topImage") {
        this.topImage = window_data.selectedFiles;
      } else if (window_data.type == "image") {
        this.image = window_data.selectedFiles;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      }
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    edit() {
      this.formData2.append("id", this.curid._id);
      this.formData2.append("name", this.curid.name);
      this.formData2.append("listingOrder", this.curid.listingOrder);
      this.formData2.append("icon", this.topImage);
      axios({
        method: "POST",
        url: "/admin/category/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.formData2,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  
  <style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.cus {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>